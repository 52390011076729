import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "TedTalks for Veterans in Transition: how to talk so people will listen",
  "author": "Purepost",
  "date": "2018-02-09T06:26:52.000Z",
  "categories": ["Job Seeking"],
  "slug": "ted-talks-for-veterans-in-transition-how-to-talk-so-people-will-listen",
  "draft": false,
  "meta_title": "TedTalks for Veterans in Transition: how to talk so people will listen"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Ever wonder if people are really listening to you? As we've seen in previous `}<a parentName="p" {...{
        "href": "https://www.ted.com/talks"
      }}>{`TedTalk`}</a>{` highlights, there are great behavioral methods to adopt to be not only a more effective communicator, but also someone who others' actually listen, absorb, and react to.`}</p>
    <p>{`As your transition from military to civilian life involves many conversations where you need answers, assistance, opportunities, and results, `}<a parentName="p" {...{
        "href": "http://www.juliantreasure.com/"
      }}>{`Julian Treasure`}</a>{` has some great tips on how to speak so that people WANT to listen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      